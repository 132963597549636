import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Admin({ solutions, add_ons }) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddForm2, setShowAddForm2] = useState(false);
  const [form, setForm] = useState({});
  const navigate = useNavigate();
  const [showEditForm1, setShowEditForm1] = useState(false);
  const [showEditForm2, setShowEditForm2] = useState(false);



  const handleChange = (e) => {
    // console.log(e.target.value,e.target.name);
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    console.log(form)
    try {

      const response = await fetch("https://jsr-prime.vercel.app/addvs", {
        method: "post",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json"
        }
      });
      let resp = await response.json()
      console.log(resp);
    } catch (e) {
      console.log(e)
    }
    // console.log(form);
  }

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    console.log(form)
    try {

      const response = await fetch("https://jsr-prime.vercel.app/addaddons", {
        method: "post",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json"
        }
      });
      let resp = await response.json()
      console.log(resp);
    } catch (e) {
      console.log(e)
    }
    // console.log(form);
  }

  const handleAddFormToggle = () => {
    setShowAddForm(!showAddForm);
  };
  const handleAddFormToggle2 = () => {
    setShowAddForm2(!showAddForm2);
  };
  const handleDelete = (itemId) => {
    const confirmDeletion = window.confirm('Are you sure you want to delete this item?');

    if (confirmDeletion) {
      deleteItem(itemId);
    } else {
      console.log('Deletion cancelled');
    }
  };

  const deleteItem = async (itemId) => {
    try {
      const response = await fetch(`https://jsr-prime.vercel.app/delete/verticalSolution/${itemId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error during deletion:', error);
    }
  };


  const handleEdit = (id,Heading,Description,Price) => {
    setShowEditForm1(true);
    setForm({
      _id: id||"",
      Heading: Heading||"",
      Description: Description||"",
      Price: Price||""
    })
  }
  const handleEdit2 = (id,Heading,Description,Price) => {
    setShowEditForm2(true);
    setForm({
      _id: id||"",
      Heading: Heading||"",
      Description: Description||"",
      Price: Price||""
    })
  }

  const handleEditForm1 = async(e) => {
    e.preventDefault();
    console.log(form);


    try {
      // const response = await fetch(`http://localhost:8000/updateVs`, {
      const response = await fetch(`https://jsr-prime.vercel.app/updateVs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
        setShowEditForm1(false);
      } else {
        console.error(data.message);
        setShowEditForm1(false)
      }
    } catch (error) {
      console.error('Error during Updation:', error);
    }
    
  }
  const handleEditForm2 = async(e) => {
    e.preventDefault();
    console.log(form);


    try {
      // const response = await fetch(`http://localhost:8000/updateAddons`, {
      const response = await fetch(`https://jsr-prime.vercel.app/updateAddons`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
        setShowEditForm2(false);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error during Updation:', error);
    }
    
  }

  const handleDelete2 = (Id,e) => {
    e.preventDefault();
    const confirmDeletion = window.confirm('Are you sure you want to delete this item?');

    if (confirmDeletion) {
      deleteItem2(Id);
    } else {
      console.log('Deletion cancelled');
    }
  };

  const deleteItem2 = async (itemId) => {
    
    try {
      const response = await fetch(`https://jsr-prime.vercel.app/delete/addOns/${itemId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error during deletion:', error);
    }
  };
  const logout = () => {
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('Login');
    navigate('/');
  }


  return (
    <>
      <div className='h-screen'>
        <div dir='rtl'><button onClick={logout} className='w-fit m-10 px-5 py-3 border-black border-2 rounded-lg hover:bg-black hover:text-white button'><i className="fa fa-sign-out ml-5"></i>Logout</button></div>
        <div className='w-[80vw] m-auto h-[60vh] flex items-center justify-between'>
          <h1 className='font-bold text-5xl'>Hey Mr.{sessionStorage.getItem('name')}👋 <br />Here you can manage your site</h1>
        </div>
      </div>
      <hr /><hr />
      <section className='mt-20 mb-20 relative'><h1 className="font-bold text-5xl text-center">Modules</h1>
        <div className='w-full'>
          <div className='flex flex-wrap justify-evenly'>
            <div className={`fixed addform z-50 ${showAddForm ? 'block' : 'hidden'}`} id='addform'>
            <div className='absolute right-4 top-2 cursor-pointer text-4xl' onClick={()=>{setShowAddForm(false)}}>×</div>
              <form onSubmit={handleSubmit1} className='bg-white rounded-lg border-black border-2 py-20 px-10'>
                <input type="text" name='Heading' placeholder='Heading' className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /> <br /><br />
                <textarea rows="5" cols="50"  name='Description' placeholder='Description' className='border-400 px-5 py-2 border rounded-xl' onChange={handleChange} ></textarea><br /><br />
                <input type="text" name='Price' placeholder='Price' className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /><br /><br />
                <input type="submit" value="Add Module" className='bg-green-600 rounded-lg p-2 cursor-pointer' />
              </form>
            </div>
            <div className={`fixed addform z-50 ${showEditForm1 ? 'block' : 'hidden'}`} id='addform'>
              <div className='absolute right-4 top-2 cursor-pointer text-4xl' onClick={()=>{setShowEditForm1(false)}}>×</div>
              <form onSubmit={handleEditForm1} className='bg-white py-20 px-10 rounded-lg border-black border-2'>
                <input type="hidden" value={form._id} />
                <input type="text" name='Heading' placeholder='Heading'value={form.Heading} className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /> <br /><br />
                <textarea rows="5" cols="50"  name='Description' value={form.Description} placeholder='Description' className='border-400 px-5 py-2 border rounded-xl' onChange={handleChange} ></textarea><br /><br />
                <input type="text" name='Price' placeholder='Price' value={form.Price} className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /><br /><br />
                <input type="submit" value="Edit Module" className='bg-green-600 rounded-lg text-white p-2 cursor-pointer' />
              </form>
            </div>
            {solutions.map((solution, index) => (
              <div key={index} className='w-80 inner-shadow-example h-[42vh] rounded-3xl p-5 relative m-auto mt-16 landscape:h-[42vw] md:landscape:h-[42vh]' id={solution.Heading}>
                <img src="resources/cube white.png" alt="" className='absolute top-10 left-3 w-10' />
                <input type="hidden" value={solution._id} />
                <div className='py-1 px-3 border-b-2 border-gray-300 mb-10 ml-6 h-[18%] flex place-items-end'>
                  <h1 className='text-lg text-gray-500'>{solution.Heading}</h1>
                </div>
                <div className='w-56 m-auto font-light text-sm text-gray-700 h-[52%]'>
                  {solution.Description} <br />
                  {solution.Price}
                </div>
                <div className='flex justify-between' ><button onClick={() => handleEdit(solution._id,solution.Heading,solution.Description,solution.Price) } className='bg-blue-600 hover:bg-blue-800 text-white w-20 p-2 rounded-lg ' type='button'>Edit</button>
                  <button onClick={() => handleDelete(solution._id)} className='bg-red-600 text-white w-20 p-2 rounded-lg hover:bg-red-800'>Delete</button></div>
              </div>
            ))}
          </div>
        </div>
        <button onClick={handleAddFormToggle} className='rounded-full bg-gray-300 hover:bg-black hover:text-white text-4xl text-center w-14 h-14 absolute right-10 pb-2 button'>+</button>
      </section>
      <hr /><hr />
      <section className='mt-20 mb-20 relative'>
        <h1 className="font-bold text-5xl text-center">Add-Ons</h1>
        <div className='w-full'>
          <div className='flex flex-wrap justify-evenly'>
            <div className={`fixed addform z-50 ${showAddForm2 ? 'block' : 'hidden'}`} id='addform'>
            <div className='absolute right-4 top-2 cursor-pointer text-4xl' onClick={()=>{setShowAddForm2(false)}}>×</div>
              <form onSubmit={handleSubmit2} className='bg-white py-20 px-10 rounded-lg border-black border-2'>
                <input type="text" name='Heading' placeholder='Heading' className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /> <br /><br />
                <textarea rows="5" cols="50" name='Description' placeholder='Description' className='border-400 px-5 py-2 border rounded-xl' onChange={handleChange} ></textarea><br /><br />
                <input type="text" name='Price' placeholder='Price' className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /><br /><br />
                <input type="submit" value="Add Module" className='bg-green-600 rounded-lg p-2 cursor-pointer text-white' />
              </form>
            </div>
            <div className={`fixed addform z-50 ${showEditForm2 ? 'block' : 'hidden'}`} id='addform'>
              <div className='absolute right-4 top-2 cursor-pointer text-4xl' onClick={()=>{setShowEditForm2(false)}}>×</div>
              <form onSubmit={handleEditForm2} className='bg-white py-20 px-10 rounded-lg border-black border-2'>
                <input type="hidden" value={form._id} />
                <input type="text" name='Heading' placeholder='Heading'value={form.Heading} className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /> <br /><br />
                <textarea rows="5" cols="50"  name='Description' value={form.Description} placeholder='Description' className='border-400 px-5 py-2 border rounded-xl' onChange={handleChange} ></textarea><br /><br />
                <input type="text" name='Price' placeholder='Price' value={form.Price} className='border-400 w-full px-5 py-2 border rounded-xl' onChange={handleChange} /><br /><br />
                <input type="submit" value="Edit AddOn" className='bg-green-600 rounded-lg text-white p-2 cursor-pointer' />
              </form>
            </div>
            {add_ons.map((addon, index) => (
              <form>
                <div key={index} className='w-80 inner-shadow-example h-[42vh] rounded-3xl p-5 relative m-auto mt-16 cursor-pointer landscape:h-[42vw] md:landscape:h-[42vh]' id={addon.Heading}>
                  <img src="resources/cube white.png" alt="" className='absolute top-10 left-3 w-10' />
                  <input type="hidden" value={addon._id} name='id' />
                  <div className='py-1 px-3 border-b-2 border-gray-300 mb-10 ml-6 h-[18%] flex place-items-end'>
                    <h1 className='text-lg text-gray-500'>{addon.Heading}</h1>
                  </div>
                  <div className='w-56 m-auto font-light text-sm text-gray-700 h-[52%]'>
                    {addon.Description} <br />
                    {addon.Price}
                  </div>
                  <div className='flex justify-between' ><button onClick={() => handleEdit2(addon._id,addon.Heading,addon.Description,addon.Price)} className='bg-blue-600 hover:bg-blue-800 text-white w-20 p-2 rounded-lg ' type='button'>Edit</button>
                    <button onClick={(e) => handleDelete2(addon._id,e)} className='bg-red-600 hover:bg-red-800 text-white w-20 p-2 rounded-lg'>Delete</button></div>
                </div>
              </form>
            ))}
          </div>
        </div>
        <button onClick={handleAddFormToggle2} className='rounded-full bg-gray-300 hover:bg-black hover:text-white text-4xl text-center w-14 h-14 absolute right-10 pb-2 button'>+</button>
      </section>
    </>
  )
}
